import Amplify from 'aws-amplify';
import * as config from './config';

const ENV_CONFIG = config.get();

const configureAmplify = () => {
  const oauth = {
    domain: ENV_CONFIG.cognitoDomain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: ENV_CONFIG.signinUrl,
    redirectSignOut: ENV_CONFIG.signoutUrl,
    responseType: 'code',
    options: {
      AdvancedSecurityDataCollectionFlag: true
    }
  };

  Amplify.configure({
    // 'aws_appsync_graphqlEndpoint': 'https://iuipfrgogrbmlnxaphpt7p5mxi.appsync-api.us-west-2.amazonaws.com/graphql',
    // 'aws_appsync_region': 'us-west-2',
    'graphql_endpoint': ENV_CONFIG.graphqlApi,
    'graphql_endpoint_iam_region': 'us-west-2',
    'aws_appsync_authenticationType': 'AWS_IAM',
    Auth: {
      identityPoolId: ENV_CONFIG.cognitoIdentityPoolId,
      region: 'us-west-2',
      userPoolId: ENV_CONFIG.cognitoUserPoolId,
      userPoolWebClientId: ENV_CONFIG.cognitoWebClientId,
      mandatorySignIn: false,
      oauth,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    }
  });
};

export default configureAmplify;