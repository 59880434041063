/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// import Auth from '@aws-amplify/auth'
// import { setUser } from './src/utils/auth'
import configAmplify from './amplify';
import * as Sentry from '@sentry/browser';
import * as config from './config';

const ENV_CONFIG = config.get();


export const onClientEntry = () => {
  configAmplify();
  if (window.location.hostname !== 'localhost') {
    Sentry.init({dsn: ENV_CONFIG.sentryDSN});
  } else {
    console.log('omitting sentry for localdev');
  }
}

export const onRouteUpdate = (state, page, pages) => {
  /*
    Auth.currentAuthenticatedUser()
      .then(user => {
        const userInfo = {
          ...user.attributes,
          username: user.username
        }
        setUser(userInfo)
      })
      .catch(err => {
        window.localStorage.setItem('gatsbyUser', null)
      })
      */
  }