
const DEV_CDN_URL='https://www.acorn.dev.plugtrays.com';
const DEV_API_URL='https://api.acorn.dev.plugtrays.com';

const PROD_CDN_URL='https://www.plugtrays.com';
const PROD_API_URL='https://api.acorn.dev.plugtrays.com';

const ENVIRONMENT_CONFIG = {
    local: {

    },
    dev: {
      sentryDSN: 'https://0a9a2c77cef74f0a954f3b24c31c3018@sentry.io/2020577',
      productImageRawCDNPath: `${DEV_CDN_URL}/product-images/`,
      productImageCDNPath: `${DEV_CDN_URL}/admin-images/`,
      mvgSiteUrl: 'https://www.dev.mountainvalleygrowers.com',
      siteUrl: DEV_CDN_URL,
      graphqlBuildApi: `${DEV_API_URL}/graphql-build`,
      graphqlBuildApiKey: 'Iy487AwiCz8E7eMUuLjaqj06vZPW1343v7QcELG5',
      cognitoDomain: 'users.acorn.dev.plugtrays.com',
      graphqlApi: `${DEV_API_URL}/graphql`,

      cognitoUserPoolId: 'us-west-2_XGvZNrzXa',
      cognitoWebClientId: '5o8hqhp50q6g1dfgbsvkqe4agu',
      cognitoIdentityPoolId: 'us-west-2:eac2caca-d7a7-47d4-bfcb-b8f0527074d5',
      gaTrackingId: 'UA-155171289-1',
    },
    prod: {
      sentryDSN: '',
      productImageRawCDNPath: `${PROD_CDN_URL}/product-images/`,
      productImageCDNPath: `${PROD_CDN_URL}/admin-images/`,
      mvgSiteUrl: 'https://www.mountainvalleygrowers.com',
      siteUrl: PROD_CDN_URL,
      graphqlBuildApi: `${PROD_API_URL}/graphql-build`,
      graphqlBuildApiKey: 'dsZULNYObf3cl4EZlxIPb3RWrDTbW6kJ7agHGzx5',
      cognitoDomain: 'users.plugtrays.com',
      graphqlApi: `${PROD_API_URL}/graphql`,
      cognitoUserPoolId: 'us-west-2_zgItoCJTT',
      cognitoWebClientId: '7favv9pjjtrkodhcj2vtjbl6h6',
      cognitoIdentityPoolId: 'us-west-2:3fcd83c7-a4ba-4518-8faf-3ee6dce834a0',
      gaTrackingId: 'UA-155056885-1',
    }
  };
  
const get = () => ENVIRONMENT_CONFIG[process.env.ACORN_ENVIRONMENT || 'dev'];

module.exports = {
  get
}
