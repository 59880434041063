// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-js": () => import("../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-with-toc-js": () => import("../src/templates/page_with_toc.js" /* webpackChunkName: "component---src-templates-page-with-toc-js" */),
  "component---src-templates-product-js": () => import("../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-category-js": () => import("../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-organic-plants-in-stock-js": () => import("../src/pages/organic-plants-in-stock.js" /* webpackChunkName: "component---src-pages-organic-plants-in-stock-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

